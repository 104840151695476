import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';

import Modal from 'react-modal';

import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import API from '../services/api';

/**
 * import { ToastContainer, toast } from "react-toastify";
 * import "react-toastify/dist/ReactToastify.css";
 * import Utils from "../../src/utils";
 */

import '../css/all.css';
import '../css/aos.css';
import '../css/cookieconsent.min.css';
import '../css/magnific-popup.css';
import '../css/odometer-theme-minimal.css';
import '../css/prism-okaidia.css';
import '../css/simplebar.css';
import '../css/smart_wizard.css';
import '../css/smart_wizard_theme_arrows.css';
import '../css/smart_wizard_theme_circles.css';
import '../css/smart_wizard_theme_dots.css';
import '../css/swiper.css';
import '../css/theme.css';
import '../css/rtl.css';

import Logo from '../assets/Home/logoTerapiaInterativaNavBar.svg';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 5,
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: 999,
  },
};

function ModalForm({ modalIsOpen, setIsOpen }) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [submitButton, setSubmitButton] = useState('COMEÇAR');

  const checkPass = (password) => {
    if (password.length < 6) {
      return 'A senha deve ter pelo menos 6 caracteres!';
    } else if (password.length > 50) {
      return 'Senha muito longa!';
    } else if (password.search(/\d/) === -1) {
      return 'Sua senha deve conter pelo menos 1 número!';
    } else if (password.search(/[a-zA-Z]/) === -1) {
      return 'Sua senha deve conter pelo menos 1 letra!';
    }
    return 'OK';
  };

  const submitAdd = (obj) => {
    return API.post(`/index/validar-cadastro-inicio`, obj);
  };

  const submitLogin = (obj) => {
    return API.post(`/index/dologin`, obj);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setSubmitButton('Processando');

    if (name === '' || email === '' || password === '') {
      toast.warn('Preencha todos os campos...', {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setSubmitButton('COMEÇAR');
      return false;
    }
    let checkPassword = checkPass(password);
    if (checkPassword !== 'OK') {
      toast.warn(checkPassword, {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setSubmitButton('COMEÇAR');
      return false;
    }

    const obj = new URLSearchParams();
    obj.append('nome', name);
    obj.append('email', email);
    obj.append('senha', password);
    obj.append('lingua', 1);

    await submitAdd(obj)
      .then((resp) => {
        if (!resp.data.error) {
          toast.success(
            'Muito obrigado pelo cadastro! Aguarde enquanto você é redirecionado para o ambiente administrativo!',
            {
              position: 'bottom-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          setTimeout(async () => {
            const obj2 = new URLSearchParams();
            obj2.append('fUsuario', email);
            obj2.append('fSenha', password);

            await submitLogin(obj2)
              .then((resp) => {
                if (resp.data.nome === '') {
                  if (resp.data.nao_logar) {
                    toast.error(
                      'Seu acesso está bloqueado. Entre em contato com o administrador da sua clínica.',
                      {
                        position: 'bottom-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      }
                    );
                  } else {
                    toast.error('Dados Incorretos. Tente novamente.', {
                      position: 'bottom-center',
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  }
                } else {
                  window.location.href = `https://app.terapiainterativa.com.br/gc/index/do-login-externo/?psico_user=${email}&psico_pass=${password}`;
                }
              })
              .catch((err) => {
                toast.error('Error Login, tente novamente mais tarde.', {
                  position: 'bottom-center',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              });
          }, 5000);
        }
      })
      .catch((err) => {
        toast.error('Error Cadastro, tente novamente mais tarde.', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setSubmitButton('COMEÇAR');
      });
  };

  useEffect(() => {
    function load() {
      const { search } = document.location;
      if (search !== '' && search !== '?p=') {
        if (search.includes('?p=')) {
          console.log(`Parceiro: ${search.substring(3)}`);
          localStorage.setItem('@psicosite:partner', search.substring(3));
        }
      }
    }
    load();
  }, []);

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        contentLabel="Assinar"
      >
        <div
          id="modal-dialog"
          style={isMobile ? {} : { maxWidth: 680 }}
          className="container mfp-with-anim popup-wrapper bg-contrast"
        >
          <div
            className="row"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                margin: 4,
              }}
            >
              <b style={{ fontSize: 24 }}>Olá, pessoal!</b>
              <br />
            </p>

            <p
              style={{
                margin: 4,
              }}
            >
              Antes de mais nada, desejamos a todos os clientes da Terapia
              Interativa um Feliz Natal e um próspero Ano Novo!
              <br />
            </p>

            <p
              style={{
                margin: 4,
              }}
            >
              Temos novidades importantes para compartilhar:{' '}
              <b style={{ fontWeight: 600 }}>
                a partir de 2025, a Terapia Interativa encerra suas atividades e
                inicia uma nova etapa por meio de uma parceria com o
                PsicoManager
              </b>
              , um sistema completo e inovador para a gestão de consultórios de
              psicologia.
              <br />
            </p>

            <p
              style={{
                margin: 4,
              }}
            >
              Com o PsicoManager, vocês terão acesso a uma plataforma moderna e
              repleta de funcionalidades que tornarão o dia a dia no consultório
              ainda mais eficiente e produtivo.
              <br />
            </p>

            <p
              style={{
                margin: 4,
              }}
            >
              Agradecemos profundamente pela confiança e por todo o tempo que
              caminhamos juntos nesta jornada. Estamos certos de que essa
              transição trará benefícios ainda maiores para vocês!
              <br />
            </p>

            <p
              style={{
                margin: 4,
                width: '100%',
              }}
            >
              Um abraço, Equipe Terapia Interativa!
            </p>
          </div>
          <div
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <button
              onClick={() => {
                window.open('https://www.psicomanager.com.br/', '_blank');
              }}
              className="btn btn-rounded btn-info mt-2"
            >
              Conheça o PsicoManager
            </button>
          </div>
        </div>
      </Modal>

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default ModalForm;
